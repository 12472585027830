import { LOCAL_STORAGE_KEYS } from '@/utils/constant.js';

export const AUTH_TOKEN = LOCAL_STORAGE_KEYS.AUTH;
export const TOKEN_TYPE = {
  BEARER: 'Bearer',
};
export const AUTH_STATUS = {
  SIGN_IN: 'sign in',
  SIGN_OUT: 'sign out',
  PENDING: 'pending',
  CHANGE_PASSWORD: 'change password',
};

export const getToken = () => {
  return localStorage.getItem(AUTH_TOKEN);
};
export const removeToken = () => {
  return localStorage.removeItem(AUTH_TOKEN);
};
export const setToken = (token) => {
  localStorage.setItem(AUTH_TOKEN, token);
};
