import { Navigate } from 'react-router-dom';

import UsePathAccess from '@/hooks/usePathAccess.js';
import { _useAuth } from '@/modules/Auth/stores/auth-store.js';
import { routeMenuMap, ROUTES } from '@/utils/routes.js';

const ProtectedRoute = ({ route, children }) => {
  const { isRouteAvailable } = UsePathAccess();
  const user = _useAuth((state) => state.user);

  const isAvailable = isRouteAvailable(route, routeMenuMap[route]);

  if (!isAvailable && user) {
    return <Navigate to={ROUTES.root} />;
  }
  return children;
};

export default ProtectedRoute;
