import { useRouteError } from 'react-router-dom';
function Error() {
  const error = useRouteError();

  return (
    <div>
      <h3>Something went wrong ...</h3>
      <p>
        <i>{error.statusText || error.message}</i>
      </p>
    </div>
  );
}

export default Error;
