import { create } from 'zustand';

import {
  changePasswordReq,
  signInReq,
  signOutReq,
} from '@/modules/Auth/api/index.js';
import { getUserInfo } from '@/modules/Users/api/index.js';
import { AUTH_STATUS } from '@/utils/auth.js';
import { getToken, removeToken, setToken } from '@/utils/auth.js';
import { ERROR_CODES } from '@/utils/constant.js';
import { getErrorMessage } from '@/utils/error/index.js';

const initialState = {
  status: AUTH_STATUS.PENDING,
  token: null,
  user: null,
  authError: null,
};

export const _useAuth = create((set, get) => ({
  ...initialState,

  signIn: async ({ username, password }) => {
    try {
      const signInResponse = await signInReq({
        username,
        password,
      });
      const token = signInResponse?.data?.access_token;
      setToken(token);

      const userResponse = await getUserInfo();

      set({
        status: AUTH_STATUS.SIGN_IN,
        token,
        user: userResponse.data,
        authError: null,
      });

      return get();
    } catch (e) {
      if (e?.response?.status !== ERROR_CODES.FORBIDDEN) {
        set({ authError: getErrorMessage(e) });
      }

      return get();
    }
  },
  signOut: async () => {
    try {
      if (get().token) {
        await signOutReq();
      }
      removeToken();
      set({ ...initialState, status: AUTH_STATUS.SIGN_OUT });
    } catch (e) {
      removeToken();
      set({ ...initialState, status: AUTH_STATUS.SIGN_OUT });
    }
  },
  changePassword: async ({ username, oldPassword, newPassword }) => {
    try {
      await changePasswordReq({
        username: username,
        password: oldPassword,
        new_password: newPassword,
      });

      const signInResponse = await signInReq({
        username: username,
        password: newPassword,
      });
      const token = signInResponse.data?.access_token;
      setToken(token);

      const userResponse = await getUserInfo();
      const user = userResponse.data;
      set({
        token: token,
        status: AUTH_STATUS.SIGN_IN,
        user: user,
        authError: null,
      });
      return get();
    } catch (e) {
      set({ authError: getErrorMessage(e) });

      return get();
    }
  },
  hydrate: async () => {
    try {
      const token = getToken();

      if (token !== null) {
        const userResponse = await getUserInfo();
        const user = userResponse.data;

        set({
          status: AUTH_STATUS.SIGN_IN,
          user,
          token,
          authError: null,
        });
      } else {
        await get().signOut();
      }
    } catch (e) {
      await get().signOut();
    }
  },
  setChangePasswordStatus: () => {
    set({
      status: AUTH_STATUS.CHANGE_PASSWORD,
      authError: null,
    });
  },
}));

export const hydrateAuth = () => _useAuth.getState().hydrate();
