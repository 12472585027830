import { NavLink, useLocation } from 'react-router-dom';

import UsePathAccess from '@/hooks/usePathAccess.js';
import { _useAuth } from '@/modules/Auth/stores/auth-store.js';
import { ROUTE_MENU, ROUTES } from '@/utils/routes.js';

import classes from './NavigationPanel.module.scss';

export default function NavigationPanel() {
  const user = _useAuth((state) => state.user);
  const { pathname } = useLocation();
  const { isRouteAvailable } = UsePathAccess();

  return (
    <nav className={classes.nav}>
      <ul className={classes.list}>
        {ROUTE_MENU.filter(({ roles }) => {
          return !roles?.length || roles.includes(user?.role_id);
        })
          .filter((routeItem) => {
            const { route, permissionKeys } = routeItem;

            const isAvailable = isRouteAvailable(
              route,
              permissionKeys,
              user?.role?.permissions,
            );

            return isAvailable && route?.includes('admin');
          })
          .map((routeItem) => {
            const { label, route } = routeItem;
            let active =
              route === pathname ||
              (pathname === ROUTES.root && route === ROUTES.materials);

            return (
              <li key={label} className={classes.listItem}>
                <NavLink
                  className={`${classes.link} ${active && classes.activeLink}`}
                  to={route}
                >
                  {label}
                </NavLink>
              </li>
            );
          })}
      </ul>
    </nav>
  );
}
