import _ from 'lodash';

import { PER_PAGE } from '@/modules/Tasks/store/index.js';
import { getPreviewPathStr } from '@/utils/image.js';

export const getPagesCount = (count, perPage) => {
  return Math.ceil(count / perPage);
};
/* get src depending on:
 * we get file from server
 * or just uploaded it
 * or we need src object */
export const getImageSrc = (
  background_day,
  isForImgTag = false,
  isPublic = false,
  isPreview = false,
) => {
  if (!background_day) return '';
  const ROOT_URL = isPublic
    ? import.meta.env.VITE_PUBLIC_ROOT_IMAGE_URL
    : import.meta.env.VITE_ROOT_IMAGE_URL;
  // just uploaded from local PC
  if (background_day?.path) {
    if (isForImgTag) {
      // return src for image
      return URL.createObjectURL(background_day);
    }
    return `url(${URL.createObjectURL(background_day)})`;
  }
  // was already uploaded
  if (background_day?.name) {
    if (isForImgTag) {
      if (isPreview) {
        return `${ROOT_URL}${getPreviewPathStr(background_day.name)}`;
      }
      // return src for image
      return `${ROOT_URL}${background_day.name}`;
    }
    if (isPreview) {
      return `url(${ROOT_URL}${getPreviewPathStr(background_day.name)})`;
    }
    return `url(${ROOT_URL}${background_day.name})`;
  }
  return `url(${background_day?.name})`;
};
export const getQuery = (page, order_by, order_direction) => {
  return _.omitBy(
    {
      offset: (page - 1) * PER_PAGE || 0,
      limit: PER_PAGE,
      order_by,
      order_direction,
    },
    (v) => _.isUndefined(v) || _.isNull(v) || v === '',
  );
};

export const createRgbString = (colorArray) => {
  if (colorArray.length !== 3) {
    throw new Error('Color array must contain exactly 3 values');
  }
  return `rgb(${colorArray[0]}, ${colorArray[1]}, ${colorArray[2]})`;
};

export const stringToBoolean = (str) => {
  if (str === 'true') {
    return true;
  } else if (str === 'false') {
    return false;
  } else {
    return !!str;
  }
};

export const removeDuplicates = (arr) => {
  return [...new Set(arr)];
};

export const customCompare = (str1, str2) => {
  if (str1 === 'null' && str2 === null) {
    return true;
  }
  if (str2 === 'null' && str1 === null) {
    return true;
  }
  return +str1 === +str2;
};
export const getTileImages = (images = []) => {
  return images?.filter((i) => i?.image_type === 'tile');
};

export const getTextAfterApi = (url) => {
  const regex = /\/api\/([\w-]+(?:\/[\w-]+)*)(?=\?|$)/;
  const match = url.match(regex);

  if (match) {
    return match[1];
  } else {
    return null;
  }
};
export const EMPTY_VALUE = 'empty';
export const EMPTY_OPTION = { value: EMPTY_VALUE, label: 'Empty' };
export const isBoolean = (value) => typeof value === 'boolean';
