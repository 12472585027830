export const AXIOS_TIMEOUT = 60000;
export const REQUEST_TYPE = Object.freeze({
  GET: 'GET',
  POST: 'POST',
  PUT: 'PUT',
  DELETE: 'DELETE',
  PATCH: 'PATCH',
  HEAD: 'HEAD',
  OPTIONS: 'OPTIONS',
  get: 'GET',
  post: 'ADD',
  put: 'UPDATE',
  delete: 'DELETE',
  patch: 'UPDATE',
  head: 'head',
  options: 'options',
});
