import { HEADER_TAB } from '@/shared/components/Header/MaterialHeader/index.js';
import { PERMISSION_KEYS_ENUM } from '@/utils/permissions/index.js';

export const ROUTES = {
  root: '/',

  login: '/login',
  materials: '/materials',
  changePassword: '/change-password',

  admin: '/admin',
  collections: '/admin/collections',
  installShots: '/admin/install-shots',
  renderTypes: '/admin/render-types',
  brands: '/admin/brands',
  surfaces: '/admin/surfaces',
  presets: '/admin/presets',
  presetClasses: '/admin/preset-classes',
  logView: '/admin/log-view',
  scenesPreview: '/admin/scenes-preview',
  scenesPreviewDetail: '/admin/scenes-preview/:id',
  account: '/admin/account',
  roles: '/admin/roles',
  users: '/admin/users',
  scenes: '/admin/scenes',

  all: '*',
};
export const ROUTES_REGEXP = {
  root: /^\/$/,
  login: /^\/login$/,
  materials: /^\/materials$/,
  changePassword: /^\/change-password$/,
  admin: /^\/admin$/,
  collections: /^\/admin\/collections$/,
  renderTypes: /^\/admin\/render-types$/,
  brands: /^\/admin\/brands$/,
  surfaces: /^\/admin\/surfaces$/,
  presets: /^\/admin\/presets$/,
  presetClasses: /^\/admin\/preset-classes$/,
  logView: /^\/admin\/log-view$/,
  scenesPreview: /^\/admin\/scenes-preview$/,
  scenesPreviewDetail: /^\/admin\/scenes-preview\/[^/]+$/, // Matches /admin/scenes-preview/:id
  account: /^\/admin\/account$/,
  roles: /^\/admin\/roles$/,
  users: /^\/admin\/users$/,
  scenes: /^\/admin\/scenes$/,
};

export const ROUTE_MENU = [
  {
    route: ROUTES.root,
    label: '',
    permissionKeys: [PERMISSION_KEYS_ENUM.MATERIALS],
  },
  {
    route: ROUTES.materials,
    label: '',
    permissionKeys: [PERMISSION_KEYS_ENUM.MATERIALS],
  },
  {
    route: ROUTES.users,
    label: 'Users',
    permissionKeys: [PERMISSION_KEYS_ENUM.USERS],
  },
  {
    route: ROUTES.brands,
    label: 'Brands',
    permissionKeys: [PERMISSION_KEYS_ENUM.MANUFACTURERS],
  },
  {
    route: ROUTES.presets,
    label: 'Presets',
    permissionKeys: [PERMISSION_KEYS_ENUM.PRESETS],
  },
  {
    route: ROUTES.presetClasses,
    label: 'Preset classes',
    permissionKeys: [PERMISSION_KEYS_ENUM.PRESET_CLASSES],
  },
  {
    route: ROUTES.logView,
    label: 'Log view',
    permissionKeys: [PERMISSION_KEYS_ENUM.MATERIAL_TASKS],
  },
  {
    route: ROUTES.roles,
    label: 'Roles',
    permissionKeys: [PERMISSION_KEYS_ENUM.ROLES],
  },
  {
    route: ROUTES.scenes,
    label: 'Scenes',
    permissionKeys: [PERMISSION_KEYS_ENUM.SCENES],
  },
  {
    route: ROUTES.surfaces,
    label: 'Surfaces',
    permissionKeys: [PERMISSION_KEYS_ENUM.SURFACES],
  },
  {
    route: ROUTES.renderTypes,
    label: 'Render type',
    permissionKeys: [PERMISSION_KEYS_ENUM.RENDER_TYPES],
  },
  {
    route: ROUTES.collections,
    label: 'Collections',
    permissionKeys: [PERMISSION_KEYS_ENUM.COLLECTIONS],
  },
  {
    route: ROUTES.scenesPreview,
    label: 'Scenes preview',
    permissionKeys: [PERMISSION_KEYS_ENUM.SCENES],
  },
  {
    route: ROUTES.installShots,
    label: 'Install shots',
    permissionKeys: [PERMISSION_KEYS_ENUM.INSTALL_SHOTS],
  },
  {
    route: ROUTES.account,
    label: 'My account',
    permissionKeys: [],
  },
];

export const getMainRoute = (url) => {
  try {
    const urlObj = new URL(url);
    const pathSegments = urlObj.pathname
      .split('/')
      .filter((segment) => segment !== '');
    return pathSegments.length > 0 ? pathSegments[0] : null;
  } catch (e) {
    console.error('Invalid URL:', e);
    return null;
  }
};
export const MAIN_ROUTES = {
  MATERIAL: 'materials',
  ADMIN: 'admin',
};

export const getActiveRouteTab = (route) => {
  if (route === MAIN_ROUTES.MATERIAL) {
    return HEADER_TAB.MATERIALS;
  }

  if (route === MAIN_ROUTES.ADMIN) {
    return HEADER_TAB.ADMIN;
  }

  return '';
};
export const routeMenuMap = ROUTE_MENU.reduce((acc, routeItem) => {
  acc[routeItem.route] = routeItem.permissionKeys;
  return acc;
}, {});
