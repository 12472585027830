import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';

import classes from './WithSuspense.module.scss';

export const Spinner = () => {
  return (
    <div className={classes.wrapper}>
      <CircularProgress className={classes.customSpinner} />
    </div>
  );
};

export default function WithSuspense({ children }) {
  return <React.Suspense fallback={<Spinner />}>{children}</React.Suspense>;
}
