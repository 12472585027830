import httpService from '@/api/http.service.js';
const ROUTE = 'preset-classes';

export const addPresetClass = (body) => httpService.post(ROUTE, body);
export const editPresetClass = (id, body) =>
  httpService.put(`${ROUTE}/${id}`, body);

export const getPresetClassesById = (id) => httpService.get(`${ROUTE}/${id}`);
export const getPresetClasses = (params) =>
  httpService.get(`${ROUTE}`, { params });
