import { Link, useNavigate } from 'react-router-dom';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';

import LightLogo from '@/assets/svg/LightLogo.svg?react';
import Logo from '@/assets/svg/Logo.svg?react';
import { _useAuth } from '@/modules/Auth/stores/auth-store.js';
import { THEME } from '@/modules/Theme/constant.js';
import { useTheme } from '@/modules/Theme/context.js';
import AccountMenu from '@/shared/components/AccountMenu/index.js';
import { a11yProps } from '@/shared/components/CustomTab/index.js';
import NavigationPanel from '@/shared/components/NavigationPanel/index.js';
import { TAB_MODE } from '@/utils/constant.js';
import { PERMISSION_KEYS_ENUM } from '@/utils/permissions/index.js';
import { getActiveRouteTab, getMainRoute, ROUTES } from '@/utils/routes.js';
import { checkIfTabAccessible } from '@/utils/tab.js';

import classes from './Header.module.scss';

export const HEADER_TAB = {
  MATERIALS: 'Materials',
  ADMIN: 'Admin',
};
export const HEADER_TABS = [
  {
    label: HEADER_TAB.MATERIALS,
    value: HEADER_TAB.MATERIALS,
    permissionKeys: [PERMISSION_KEYS_ENUM.MATERIALS],
  },
  {
    label: HEADER_TAB.ADMIN,
    value: HEADER_TAB.ADMIN,
    permissionKeys: [
      PERMISSION_KEYS_ENUM.USERS,
      PERMISSION_KEYS_ENUM.MANUFACTURERS,
      PERMISSION_KEYS_ENUM.PRESETS,
      PERMISSION_KEYS_ENUM.PRESET_CLASSES,
      PERMISSION_KEYS_ENUM.MATERIAL_TASKS,
      PERMISSION_KEYS_ENUM.ROLES,
      PERMISSION_KEYS_ENUM.SURFACES,
      PERMISSION_KEYS_ENUM.RENDER_TYPES,
      PERMISSION_KEYS_ENUM.COLLECTIONS,
      PERMISSION_KEYS_ENUM.SCENES,
    ],
  },
];

export default function MaterialHeader({ withNavMenu = false }) {
  const [user] = _useAuth((state) => [state.user, state.signOut]);
  const tabs = HEADER_TABS.filter((t) => {
    return checkIfTabAccessible(user?.role?.permissions, t?.permissionKeys);
  });
  const { theme } = useTheme();
  const navigate = useNavigate();
  const handleChange = (event, newValue) => {
    if (newValue === HEADER_TAB.ADMIN) {
      navigate(`${ROUTES.admin}`);
    } else {
      navigate(`${ROUTES.materials}`);
    }
  };
  const tab = getActiveRouteTab(getMainRoute(window.location.href));

  return (
    <header
      className={classes.header}
      style={{
        borderBottom: withNavMenu && 'thin solid var(--border-primary-color)',
      }}
    >
      <div className={classes.topBar}>
        <Link to={ROUTES.root}>
          {theme === THEME.DARK ? <LightLogo /> : <Logo />}
        </Link>
        <div className={classes.search}>
          <Tabs value={tab} onChange={handleChange} mode={TAB_MODE.TOP}>
            {tabs.map((t) => (
              <Tab
                className={classes.tabLabel}
                key={t.value}
                label={t.label}
                value={t?.value}
                {...a11yProps(t?.value)}
              />
            ))}
          </Tabs>
        </div>
        <div className={classes.menu}>{user && <AccountMenu />}</div>
      </div>
      {withNavMenu && <NavigationPanel />}
    </header>
  );
}
