import { useEffect, useRef } from 'react';
import IconButton from '@mui/material/IconButton';
import { useSnackbar } from 'notistack';
import { v4 as uuidv4 } from 'uuid';

import Cross from '@/assets/svg/Cross.svg?react';
import { useSnackBarStore } from '@/modules/SnackBar/store/index.js';

const SnackbarManager = () => {
  const snackbars = useSnackBarStore((state) => state.snackbars);
  const removeSnackbar = useSnackBarStore((state) => state.removeSnackbar);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const shownSnackbarsRef = useRef(new Set());

  useEffect(() => {
    const newSnackbars = snackbars.filter(
      ({ id }) => !shownSnackbarsRef.current.has(id),
    );

    newSnackbars.forEach(({ id, text, severity }) => {
      const uniqueKey = uuidv4(); // Generate a unique key for each snackbar

      enqueueSnackbar(text, {
        key: uniqueKey, // Use the unique key
        variant: severity,
        style: { whiteSpace: 'pre-line', textAlign: 'left' },
        disableWindowBlurListener: true,
        autoHideDuration: 6000,
        hideIconVariant: true,
        onClose: () => {
          removeSnackbar(id);
          shownSnackbarsRef.current.delete(id);
        },
        className: 'alert-snackbar', // Apply the custom CSS class
        action: (key) => (
          <IconButton
            size='small'
            aria-label='close'
            color='inherit'
            style={{
              background: 'white',
              width: '24px',
              height: '24px',
              padding: '4px',
            }}
            onClick={() => {
              closeSnackbar(key);
              removeSnackbar(id);
              shownSnackbarsRef.current.delete(id);
            }}
          >
            <Cross />
          </IconButton>
        ),
      });

      shownSnackbarsRef.current.add(id);
    });
  }, [snackbars, enqueueSnackbar, removeSnackbar]);

  return null;
};

export default SnackbarManager;
