import React from 'react';

export const FiltersContext = React.createContext();

export const MODAL_SIZE = {
  MD: '--filters-height-md',
  SM: '--filters-height-sm',
  LG: '--filters-height-lg',
};

export const SIZE_CLASSES = {
  [MODAL_SIZE.MD]: 'md-filters',
  [MODAL_SIZE.LG]: 'lg-filters',
  [MODAL_SIZE.SM]: 'sm-filters',
};

export const getFiltersHeight = (value) => {
  if (value === MODAL_SIZE.SM) {
    return MODAL_SIZE.MD;
  }
  if (value === MODAL_SIZE.LG) {
    return MODAL_SIZE.MD;
  }
  if (value === MODAL_SIZE.MD) {
    return MODAL_SIZE.LG;
  }
};

export const FiltersProvider = ({ children }) => {
  const [size, setSize] = React.useState(MODAL_SIZE.MD);
  const nextSize = () => {
    setSize(getFiltersHeight(size));
  };
  return (
    <FiltersContext.Provider value={{ size, setSize, nextSize }}>
      {children}
    </FiltersContext.Provider>
  );
};

export const useFilters = () => {
  const context = React.useContext(FiltersContext);
  if (context === undefined) {
    throw new Error('useTheme must be used within a ThemeProvider');
  }
  return context;
};
