import React from 'react';
import _ from 'lodash';

import { ACCESS } from '@/hooks/accessConstans.js';
import { _useAuth } from '@/modules/Auth/stores/auth-store.js';

export default function UsePathAccess() {
  const user = _useAuth((state) => state.user);

  const permissionsIds = React.useMemo(() => {
    return (user?.role?.permissions || []).map((p) => p?.id) || [];
  }, [user?.role?.permissions]);

  const checkIfAvailable = (permissionId) => {
    return permissionsIds.includes(permissionId);
  };
  const checkIfAvailableIds = (permissionIds) => {
    return permissionIds.every((id) => permissionsIds.includes(id));
  };

  const isRouteAvailable = (key, permissionKeys = []) => {
    const groupedPermissions = _.groupBy(
      user?.role?.permissions.filter(
        (p) => p?.id !== 'get_user_me_users_me_get',
      ),
      'group',
    );

    const allowedGroups = Object.keys(groupedPermissions).filter((groupKey) => {
      return groupedPermissions[groupKey]?.length >= 1;
    });

    if (!permissionKeys?.length && key !== '/admin/account') return false;
    return permissionKeys.every((k) => allowedGroups.includes(k));
  };
  const permissions = React.useMemo(() => {
    let result = {};
    Object.entries(ACCESS).forEach(([label, ids]) => {
      result[label] = checkIfAvailableIds(ids);
    });
    return result;
  }, [permissionsIds.join('')]);

  return {
    checkIfAvailableIds,
    checkIfAvailable,
    isRouteAvailable,
    permissions,
  };
}
