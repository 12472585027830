export const MuiInput = {
  styleOverrides: {
    root: {
      '&::before': {
        borderBottom: 'thin solid #4D4E58 !important',
      },
      '&::after': {
        borderBottom: 'thin solid #4D4E58 !important',
      },
    },
  },
};
