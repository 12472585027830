import { v4 as uuidv4 } from 'uuid';
import { create } from 'zustand';

export const SNACKBAR_VARIANT = {
  SUCCESS: 'success',
  ERROR: 'error',
  DEFAULT: 'default',
  UPDATED: 'warning',
};
const initialState = {
  open: false,
  text: '',
  severity: SNACKBAR_VARIANT.SUCCESS,
  snackbars: [],
};
export const useSnackBarStore = create((set, get) => ({
  ...initialState,
  setOpen: (text = '', severity = SNACKBAR_VARIANT.SUCCESS) => {
    set({
      snackbars: [
        ...get().snackbars,
        {
          text,
          severity,
          id: uuidv4(),
        },
      ],
    });
  },
  removeSnackbar: (id) => {
    set({
      snackbars: get().snackbars.filter((snackbar) => snackbar.id !== id),
    });
  },
}));
