import CheckedRadio from '@/assets/svg/design-v3/CheckedRadio.svg?react';
import UncheckedRadio from '@/assets/svg/design-v3/UncheckedRadio.svg?react';
import { MUI_THEME } from '@/modules/Theme/constant.js';

export const MuiRadio = {
  defaultProps: {
    checkedIcon: <CheckedRadio />,
    icon: <UncheckedRadio />,
  },
  styleOverrides: {
    root: ({ theme }) => {
      const idDark = theme.palette.mode === MUI_THEME['dark-mode'];

      return {
        ...(idDark && {
          '& svg path:nth-of-type(1)': {
            fill: '#222225',
          },
          '&.Mui-checked svg path:nth-of-type(1)': {
            fill: '#222225',
          },
          '&.Mui-checked svg path:nth-of-type(2)': {
            fill: '#A4A4A4',
          },
          '&:hover svg path:nth-of-type(1)': {
            fill: '#1A1A1D',
          },
          '&:hover svg path:nth-of-type(2)': {
            fill: '#FFFFFF',
          },
          '&.Mui-disabled svg rect:nth-of-type(1)': {
            fill: '#1E1E21',
          },
          '&.Mui-disabled svg rect:nth-of-type(2)': {
            fill: '#86868B',
          },
        }),
      };
    },
  },
};
