import React, { useState } from 'react';

import { LOCAL_STORAGE_KEYS } from '@/utils/constant.js';
import LocalStorageService from '@/utils/localStorage.js';

export const MaterialsContext = React.createContext();
export const ORDER_FIELD = {
  FILTER_ACTIONS: 'FilterActions',
  SEARCH_WRAPPER: 'SearchWrapper',
  STATUS: 'Status',
  BRAND: 'Brand',
  FAMILY: 'Family',
  PRESET_CLASS: 'Preset Class',
  USERS: 'Users',
};

export const DEFAULT_FILTERS_ORDER = [
  ORDER_FIELD.FILTER_ACTIONS,
  ORDER_FIELD.SEARCH_WRAPPER,
  ORDER_FIELD.STATUS,
  ORDER_FIELD.BRAND,
  ORDER_FIELD.FAMILY,
  ORDER_FIELD.PRESET_CLASS,
  ORDER_FIELD.USERS,
];
export const MaterialsProvider = ({ children }) => {
  const [action, setAction] = React.useState('');
  const [isEditLayout, setIsEditLayout] = React.useState(false);
  const [modal, setModal] = React.useState(null);
  const [filterOrder, setFilterOrder] = useState(() => {
    const savedFilterOrder = LocalStorageService.getItem(
      LOCAL_STORAGE_KEYS.FILTER_ORDER,
    );

    return savedFilterOrder ? savedFilterOrder : DEFAULT_FILTERS_ORDER;
  });

  return (
    <MaterialsContext.Provider
      value={{
        action,
        setAction,
        modal,
        setModal,
        isEditLayout,
        setIsEditLayout,
        filterOrder,
        setFilterOrder,
      }}
    >
      {children}
    </MaterialsContext.Provider>
  );
};
