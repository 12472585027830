export const DEFAULT_ERROR = 'something went wrong';
export const getErrorMessage = (e) => {
  if (Array.isArray(e?.response?.data?.detail)) {
    return e?.response?.data?.detail.map((e) => e.msg).join(', ');
  }

  return (
    e?.response?.data?.detail ||
    e?.message ||
    e?.response?.statusText ||
    DEFAULT_ERROR
  );
};
