export const MuiLabel = {
  styleOverrides: {
    root: {
      color: 'var(--text-primary) !important',
    },
    '&.Mui-focused': {
      color: 'var(--text-primary) !important',
    },
  },
};
