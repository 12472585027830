export const encodeUrlPath = (url) => {
  return encodeURI(url);
};
export const getPreviewPath = (img) => {
  return encodeUrlPath(
    `${`preview/${img?.image_route}`.split('.').slice(0, -1).join('.')}.webp`,
  );
};

export const getPreviewPathStr = (img) => {
  return encodeUrlPath(
    `${`preview/${img}`.split('.').slice(0, -1).join('.')}.webp`,
  );
};
