import { MUI_THEME } from '@/modules/Theme/constant.js';
import { stringToBoolean } from '@/utils/common.js';
import { TEXT_FIELD_VARIANTS } from '@/utils/materialTheme/constant.js';

export const MuiTextField = {
  styleOverrides: {
    root: ({ theme, ...field }) => {
      const isLight = theme.palette.mode === MUI_THEME['light-mode'];
      const zerobottomradius = stringToBoolean(field?.zerobottomradius);

      const commonStyles = {
        width: '100%',
      };

      const inputBaseRootStyles = {
        common: {
          '&::before': {
            borderBottomStyle: 'solid !important',
          },
        },
        [TEXT_FIELD_VARIANTS.SEARCH_V3]: {
          lineHeight: '2.4rem',
        },
        [TEXT_FIELD_VARIANTS.BASIC]: {
          lineHeight: '2.4rem',
          ...(!isLight && {
            '&::before': {
              borderBottom: 'thin solid var(--text-secondary)',
            },
          }),
        },
        [TEXT_FIELD_VARIANTS.PASSWORD]: {
          background: 'var(--bg-light-grey-2)',
          borderRadius: '32px',
        },
        [TEXT_FIELD_VARIANTS.SEARCH_V3]: {
          background: 'var(--dark-grey)',
          borderRadius: '8px',
          '&:hover': {
            background: 'var(--neutral-gray)',
          },
        },
        [TEXT_FIELD_VARIANTS.AUTH]: {
          background: 'var(--bg-light-grey-2)',
          borderRadius: '32px',
          ...(!isLight && { color: 'var(--text-bg-light-grey-3)' }),
        },
        [TEXT_FIELD_VARIANTS.SEARCH]: {
          paddingLeft: 0,
        },
        [TEXT_FIELD_VARIANTS.NUMBER]: {
          paddingLeft: 0,
          borderRadius: '8px',
          background: isLight ? 'var(--color-white)' : '#1D1C1C',
          minWidth: '50px',
          width: 'auto',
        },
        [TEXT_FIELD_VARIANTS.SEARCH_40_GREY]: {
          background: isLight ? 'var(--light-grey-1-color)' : '#1D1C1C',
          borderRadius: '12px',
        },
        [TEXT_FIELD_VARIANTS.SM_SEARCH]: {
          background: isLight ? 'var(--light-grey-1-color)' : '#1D1C1C',
          borderRadius: '24px',
        },
        [TEXT_FIELD_VARIANTS.LARGE_WHITE]: {
          background: isLight
            ? 'var(--color-white)'
            : 'var(--light-grey-1-color)',
          borderRadius: zerobottomradius ? '12px 12px 0 0' : '12px',
        },
        [TEXT_FIELD_VARIANTS.FL_SEARCH]: {
          background: isLight ? 'var(--light-grey-1-color)' : '#1D1C1C',
          borderRadius: '24px',
        },
        [TEXT_FIELD_VARIANTS.V3_GREY]: {
          background: isLight
            ? 'var(--light-grey-1-color)'
            : 'var(--light-grey-1-color)',
          borderRadius: '8px',
          color: 'var(--bg-medium-grey-color)',
        },
        [TEXT_FIELD_VARIANTS.INLINE]: {
          ...(!isLight && {
            '&::before': {
              borderBottom: 'thin solid #4D4E58',
            },
          }),
          width: '100%',
        },
      };

      const formLabelRootStyles = {
        [TEXT_FIELD_VARIANTS.BASIC]: {
          color: 'var(--text-secondary) !important',
        },
      };

      const inputStyles = {
        common: {
          outline: 'none',
        },
        [TEXT_FIELD_VARIANTS.BASIC]: {
          ...(!isLight && {
            '&::placeholder': {
              color: 'var(--text-secondary)',
              opacity: 1,
            },
          }),
          color: 'var(--text-primary)',
        },
        [TEXT_FIELD_VARIANTS.PASSWORD]: {
          ...(!isLight && {
            '&::placeholder': {
              color: 'var(--text-bg-light-grey-3)',
              opacity: 1,
            },
          }),
          color: 'var(--bg-dark-grey-color)',
        },
        [TEXT_FIELD_VARIANTS.AUTH]: {
          '&::placeholder': {
            textOverflow: 'ellipsis !important',
            color: isLight
              ? 'var(--text-secondary)'
              : 'var(--text-bg-light-grey-3)',
          },
          color: 'var(--text-primary)',
          padding: '2rem',
        },
        [TEXT_FIELD_VARIANTS.SEARCH]: {
          '&::placeholder': {
            color: 'var(--text-secondary) !important',
            opacity: 1,
          },
          color: 'var(--text-primary)',
          border: 'none',
          height: '31px', //remove
          minWidth: '420px', //remove
        },
        [TEXT_FIELD_VARIANTS.NUMBER]: {
          '&::placeholder': {
            color: 'var(--text-secondary) !important',
            opacity: 1,
          },
          color: 'var(--text-primary)',
          border: 'none',
          minWidth: '20px',
          lineHeight: '16px',
          height: '16px',
          padding: '.8rem .4rem',
          textAlign: 'center',
        },
        [TEXT_FIELD_VARIANTS.SEARCH_V3]: {
          ...(!isLight && {
            '&::placeholder': {
              color: 'var(--color-silver-light)',
              opacity: 1,
            },
          }),
          '&::placeholder': {
            color: 'var(--color-silver-light) !important',
            opacity: 1,
            webkitTextFillColor: 'var(--color-silver-light) !important',
          },
          '&:disabled': {
            webkitTextFillColor: 'var(--color-silver-light)',
            opacity: '.8',
          },
          color: 'var(--color-white)',
          textOverflow: 'ellipsis !important',
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          border: 'none',
          height: '23px',
          borderRadius: '12px',
          background: 'transparent',
          '&:hover': {
            background: 'var(--neutral-gray)',
          },
        },
        [TEXT_FIELD_VARIANTS.SEARCH_40_GREY]: {
          ...(!isLight && {
            '&::placeholder': {
              color: 'var(--bg-medium-grey-color)',
              opacity: 1,
            },
          }),
          color: 'var(--text-secondary)',
          border: 'none',
          height: '23px',
          minWidth: '260px',
          borderRadius: '12px',
          background: isLight ? 'var(--light-grey-1-color)' : '#1D1C1C',
        },
        [TEXT_FIELD_VARIANTS.SM_SEARCH]: {
          ...(!isLight && {
            '&::placeholder': {
              color: 'var(--bg-medium-grey-color)',
              opacity: 1,
            },
          }),
          color: 'var(--text-secondary)',
          border: 'none',
          height: '19px',
          minWidth: '260px',
          borderRadius: '24px',
          background: isLight ? 'var(--light-grey-1-color)' : '#1D1C1C',
        },
        [TEXT_FIELD_VARIANTS.V3_GREY]: {
          ...(!isLight && {
            '&::placeholder': {
              color: '#86868B',
              opacity: 1,
            },
          }),
          color: 'var(--text-secondary)',
          border: 'none',
        },
        [TEXT_FIELD_VARIANTS.FL_SEARCH]: {
          color: 'var(--text-primary)',
          border: 'none',
          height: '19px',
          minWidth: '180px',
          borderRadius: '24px',
          background: isLight ? 'var(--light-grey-1-color)' : '#1D1C1C',
          '&::placeholder': {
            color: '#86868B',
            opacity: 1,
          },
        },
        [TEXT_FIELD_VARIANTS.INLINE]: {
          fontSize: 16,
          fontWeight: 400,
          color: 'var(--bg-dark-grey-color)',
          height: '42px',
          width: '100%',
        },
        [TEXT_FIELD_VARIANTS.LARGE_WHITE]: {
          fontSize: 16,
          fontWeight: 400,
          color: 'var(--bg-dark-grey-color)',
          height: '31px',
          width: '100%',
        },
      };

      const outlinedInputNotchedOutlineStyles = {
        common: {},
        [TEXT_FIELD_VARIANTS.AUTH]: {
          border: 'none',
        },
        [TEXT_FIELD_VARIANTS.SEARCH]: {
          borderRadius: '24px',
          border: 'none',
        },
        [TEXT_FIELD_VARIANTS.NUMBER]: {
          borderRadius: '8px',
          border: 'none',
        },
        [TEXT_FIELD_VARIANTS.SEARCH_40_GREY]: {
          borderRadius: '12px',
          border: 'none',
        },
        [TEXT_FIELD_VARIANTS.SEARCH_V3]: {
          borderRadius: '12px',
          border: 'none',
        },
        [TEXT_FIELD_VARIANTS.SM_SEARCH]: {
          borderRadius: '24px',
          border: 'none',
        },
        [TEXT_FIELD_VARIANTS.FL_SEARCH]: {
          borderRadius: '24px',
          border: 'none',
        },
        [TEXT_FIELD_VARIANTS.LARGE_WHITE]: {
          border: 'none',
        },
        [TEXT_FIELD_VARIANTS.V3_GREY]: {
          border: 'none',
        },
      };

      const inputAdornmentRootStyles = {
        [TEXT_FIELD_VARIANTS.SEARCH]: {
          padding: '1.2rem .8rem 1.2rem 1.6rem',
          height: '2.4rem',
          width: '2.4rem',
        },
      };

      const iconButtonRootStyles = {
        [TEXT_FIELD_VARIANTS.AUTH]: {
          padding: '2rem',
        },
      };

      const svgPathStyles = {
        [TEXT_FIELD_VARIANTS.AUTH]: {
          fill: 'var(--bg-dark-grey-color)',
        },
      };

      const variant = Object.values(TEXT_FIELD_VARIANTS).find((variant) =>
        field.className.includes(variant),
      );

      return {
        ...commonStyles,
        '& .MuiInputBase-root': {
          ...inputBaseRootStyles.common,
          ...(variant && inputBaseRootStyles[variant]),
        },
        '& .MuiFormLabel-root': formLabelRootStyles[variant],
        input: {
          ...inputStyles.common,
          ...(variant && inputStyles[variant]),
        },
        '& .MuiOutlinedInput-notchedOutline': {
          ...outlinedInputNotchedOutlineStyles.common,
          ...(variant && outlinedInputNotchedOutlineStyles[variant]),
        },
        '& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline': {
          border: 'thin solid #d32f2f',
        },
        '& .MuiInputAdornment-root': inputAdornmentRootStyles[variant],
        '& .MuiIconButton-root': iconButtonRootStyles[variant],
        '& svg > path': svgPathStyles[variant],
        ...(variant === TEXT_FIELD_VARIANTS.PASSWORD && {
          borderRadius: '32px',
        }),
        ...(variant === TEXT_FIELD_VARIANTS.AUTH && { borderRadius: '32px' }),
        ...(variant === TEXT_FIELD_VARIANTS.SEARCH && {
          borderRadius: '24px',
          background: isLight ? 'white' : '#1D1C1C',
        }),
        ...(variant === TEXT_FIELD_VARIANTS.SEARCH_40_GREY && {
          width: '100%',
        }),
        ...(variant === TEXT_FIELD_VARIANTS.SM_SEARCH && {
          width: '100%',
        }),
        ...(variant === TEXT_FIELD_VARIANTS.FL_SEARCH && {
          width: '100%',
        }),
        '.MuiInputBase-sizeXsm': {
          padding: 0,
          '& > input': {
            fontWeight: 400,
            fontSize: 'var(--font-size-sm-1)',
            lineHeight: '16px',
            height: '16px',
            padding: '4px 8px',
          },
        },
      };
    },
  },
};
