import React from 'react';
import {
  createBrowserRouter,
  createRoutesFromElements,
  Navigate,
  Route,
  RouterProvider,
} from 'react-router-dom';
import { ThemeProvider } from '@mui/material';

import { useTheme } from '@/modules/Theme/context.js';
const ChangePassword = React.lazy(
  () => import('@/routes/ChangePassword/index.js'),
);
const Users = React.lazy(() => import('@/routes/Users/index.js'));
const Materials = React.lazy(() => import('@/routes/Materials/index.js'));
const Roles = React.lazy(() => import('@/routes/Roles/index.js'));
const Scenes = React.lazy(() => import('@/routes/Scenes/index.js'));
const Root = React.lazy(() => import('@/routes/Root/index.js'));
const ScenesPreview = React.lazy(
  () => import('@/routes/ScenesPreview/index.js'),
);
const Surfaces = React.lazy(() => import('@/routes/Surfaces/index.js'));
const RenderTypes = React.lazy(() => import('@/routes/RenderTypes/index.js'));
const Account = React.lazy(() => import('@/routes/Account/index.js'));
const Login = React.lazy(() => import('@/routes/Login/index.js'));
const Presets = React.lazy(() => import('@/routes/Presets/index.js'));
const InstallShots = React.lazy(() => import('@/routes/InstallShots/index.js'));
const PresetClasses = React.lazy(
  () => import('@/routes/PresetClasses/index.js'),
);
const LogView = React.lazy(() => import('@/routes/LogView/index.js'));
const Brands = React.lazy(() => import('@/routes/Brands/index.js'));
const Collections = React.lazy(() => import('@/routes/Collections/index.js'));
const AuthLayout = React.lazy(
  () => import('@/shared/components/AuthLayout/index.js'),
);
const AdminLayout = React.lazy(
  () => import('@/shared/components/AdminLayout/index.js'),
);
const MaterialsLayout = React.lazy(
  () => import('@/shared/components/MaterialsLayout/index.js'),
);
const ScenesPreviewForm = React.lazy(
  () => import('@/routes/ScenesPreview/ScenesPreviewForm/index.js'),
);
import { SnackbarProvider } from 'notistack';

import { FiltersProvider } from '@/contexts/filtersContext.js';
import { getBrandById } from '@/modules/Brands/api/index.js';
import { getCollectionById } from '@/modules/Collections/api/index.js';
import { getMaterialById } from '@/modules/Materials/api/index.js';
import { getPresetClassesById } from '@/modules/PresetClasses/api/index.js';
import { getPresetById } from '@/modules/Presets/api/index.js';
import { getRenderTypeId } from '@/modules/RenderTypes/api/index.js';
import { getRoleById } from '@/modules/Roles/api/index.js';
import { getSceneById } from '@/modules/Scenes/api/index.js';
import { getSurfaceById } from '@/modules/Surfaces/api/index.js';
import { getTaskById } from '@/modules/Tasks/api/index.js';
import { getUserById } from '@/modules/Users/api/index.js';
import ErrorPage from '@/shared/components/ErrorPage/index.js';
import ProtectedRoute from '@/shared/components/ProtectedRoute/index.js';
import SnackbarManager from '@/shared/components/SnackBarManager/index.js';
import WithSuspense, { Spinner } from '@/shared/Hooks/WithSuspense/index.js';
import { ROUTES } from '@/utils/routes.js';

export default function RoutesView() {
  const { muiTheme } = useTheme();

  const router = createBrowserRouter(
    createRoutesFromElements(
      <>
        <Route
          element={
            <WithSuspense>
              <AuthLayout />
            </WithSuspense>
          }
        >
          <Route
            path={ROUTES.login}
            element={
              <WithSuspense>
                <Login />
              </WithSuspense>
            }
          />
          <Route
            path={ROUTES.changePassword}
            element={
              <WithSuspense>
                <ChangePassword />
              </WithSuspense>
            }
          />
        </Route>
        <Route
          element={
            <WithSuspense>
              <MaterialsLayout />
            </WithSuspense>
          }
          path={ROUTES.materials}
        >
          <Route
            path={ROUTES.materials}
            element={
              <WithSuspense>
                <ProtectedRoute route={ROUTES.materials}>
                  <Materials />
                </ProtectedRoute>
              </WithSuspense>
            }
            errorElement={<ErrorPage />}
            loader={async ({ request }) => {
              const id = new URL(request.url).searchParams.get('edit');
              try {
                if (id) {
                  const response = await getMaterialById(id);
                  return response?.data;
                }
              } catch (e) {
                return null;
              }

              return null;
            }}
          />
        </Route>
        <Route
          element={
            <WithSuspense>
              <AdminLayout />
            </WithSuspense>
          }
          path={ROUTES.admin}
        >
          <Route
            path={ROUTES.admin}
            element={<Navigate to={ROUTES.account} replace />}
          />
          <Route
            path={ROUTES.users}
            errorElement={<ErrorPage />}
            element={
              <WithSuspense>
                <ProtectedRoute route={ROUTES.users}>
                  <Users />
                </ProtectedRoute>
              </WithSuspense>
            }
            loader={async ({ request }) => {
              const id = new URL(request.url).searchParams.get('edit');
              if (id) {
                try {
                  const response = await getUserById(id);
                  return response?.data;
                } catch (e) {
                  return null;
                }
              }

              return null;
            }}
          />
          <Route
            path={ROUTES.collections}
            errorElement={<ErrorPage />}
            element={
              <WithSuspense>
                <ProtectedRoute route={ROUTES.collections}>
                  <Collections />
                </ProtectedRoute>
              </WithSuspense>
            }
            loader={async ({ request }) => {
              const id = new URL(request.url).searchParams.get('edit');
              try {
                if (id) {
                  const response = await getCollectionById(id);
                  return response?.data;
                }
              } catch (e) {
                return null;
              }
              return null;
            }}
          />
          <Route
            path={ROUTES.account}
            element={
              <WithSuspense>
                <Account />
              </WithSuspense>
            }
          />
          <Route
            errorElement={<ErrorPage />}
            path={ROUTES.installShots}
            element={
              <WithSuspense>
                <ProtectedRoute route={ROUTES.installShots}>
                  <InstallShots />
                </ProtectedRoute>
              </WithSuspense>
            }
          />
          <Route
            path={ROUTES.brands}
            errorElement={<ErrorPage />}
            element={
              <ProtectedRoute route={ROUTES.brands}>
                <Brands />
              </ProtectedRoute>
            }
            loader={async ({ request }) => {
              try {
                const id = new URL(request.url).searchParams.get('edit');
                if (id) {
                  const response = await getBrandById(id);
                  return response?.data;
                }
              } catch (e) {
                return null;
              }

              return null;
            }}
          />
          <Route
            path={ROUTES.presetClasses}
            loader={async ({ request }) => {
              const id = new URL(request.url).searchParams.get('edit');
              try {
                if (id) {
                  const response = await getPresetClassesById(id);
                  return response?.data;
                }
              } catch (e) {
                return null;
              }
              return null;
            }}
            errorElement={<ErrorPage />}
            element={
              <ProtectedRoute route={ROUTES.presetClasses}>
                <PresetClasses />
              </ProtectedRoute>
            }
          />
          <Route
            path={ROUTES.logView}
            loader={async ({ request }) => {
              const id = new URL(request.url).searchParams.get('edit');
              try {
                if (id) {
                  const response = await getTaskById(id);
                  return response?.data;
                }
              } catch (e) {
                return null;
              }
              return null;
            }}
            errorElement={<ErrorPage />}
            element={
              <WithSuspense>
                <ProtectedRoute route={ROUTES.logView}>
                  <LogView />
                </ProtectedRoute>
              </WithSuspense>
            }
          />
          <Route
            errorElement={<ErrorPage />}
            path={ROUTES.presets}
            loader={async ({ request }) => {
              const id = new URL(request.url).searchParams.get('edit');
              try {
                if (id) {
                  const response = await getPresetById(id);
                  return response?.data;
                }
              } catch (e) {
                return null;
              }
              return null;
            }}
            element={
              <ProtectedRoute route={ROUTES.presets}>
                <Presets />
              </ProtectedRoute>
            }
          />
          <Route
            path={ROUTES.roles}
            loader={async ({ request }) => {
              const id = new URL(request.url).searchParams.get('edit');
              try {
                if (id) {
                  const response = await getRoleById(id);
                  return response?.data;
                }
              } catch (e) {
                return null;
              }
              return null;
            }}
            errorElement={<ErrorPage />}
            element={
              <WithSuspense>
                <ProtectedRoute route={ROUTES.roles}>
                  <Roles />
                </ProtectedRoute>
              </WithSuspense>
            }
          />
          <Route
            path={ROUTES.scenes}
            errorElement={<ErrorPage />}
            loader={async ({ request }) => {
              const id = new URL(request.url).searchParams.get('edit');
              try {
                if (id) {
                  const response = await getSceneById(id);
                  return response?.data;
                }
              } catch (e) {
                return null;
              }
              return null;
            }}
            element={
              <WithSuspense>
                <ProtectedRoute route={ROUTES.scenes}>
                  <Scenes />
                </ProtectedRoute>
              </WithSuspense>
            }
          />
          <Route
            path={ROUTES.scenesPreview}
            errorElement={<ErrorPage />}
            element={
              <WithSuspense>
                <ProtectedRoute route={ROUTES.scenes}>
                  <ScenesPreview />
                </ProtectedRoute>
              </WithSuspense>
            }
          />
          <Route
            errorElement={<ErrorPage />}
            path={ROUTES.scenesPreviewDetail}
            loader={async ({ request }) => {
              const id = request.url.split('/').pop();
              try {
                if (id) {
                  const response = await getSceneById(id);
                  return response?.data;
                }
              } catch (e) {
                return null;
              }
              return null;
            }}
            element={
              <WithSuspense>
                <ProtectedRoute route={ROUTES.scenes}>
                  <ScenesPreviewForm />
                </ProtectedRoute>
              </WithSuspense>
            }
          />
          <Route
            path={ROUTES.surfaces}
            errorElement={<ErrorPage />}
            loader={async ({ request }) => {
              const id = new URL(request.url).searchParams.get('edit');
              const sceneId = new URL(request.url).searchParams.get(
                'edit_scene',
              );
              const surfaceId = new URL(request.url).searchParams.get(
                'surface',
              );
              try {
                if (id) {
                  const response = await getSurfaceById(id);
                  return response?.data;
                }
                if (sceneId && surfaceId) {
                  const surfaceResponse = await getSurfaceById(surfaceId);
                  const response = await getSceneById(sceneId);

                  return {
                    scene: response?.data,
                    surface: surfaceResponse?.data,
                  };
                }
              } catch (e) {
                return null;
              }
              return null;
            }}
            element={
              <WithSuspense>
                <ProtectedRoute route={ROUTES.surfaces}>
                  <Surfaces />
                </ProtectedRoute>
              </WithSuspense>
            }
          />
          <Route
            path={ROUTES.renderTypes}
            errorElement={<ErrorPage />}
            loader={async ({ request }) => {
              const id = new URL(request.url).searchParams.get('edit');

              try {
                if (id) {
                  const response = await getRenderTypeId(id);
                  return response?.data;
                }
              } catch (e) {
                return null;
              }
              return null;
            }}
            element={
              <WithSuspense>
                <ProtectedRoute route={ROUTES.renderTypes}>
                  <RenderTypes />
                </ProtectedRoute>
              </WithSuspense>
            }
          />
        </Route>
        <Route path={ROUTES.all} element={<Root to={ROUTES.root} replace />} />
      </>,
    ),
  );

  return (
    <React.Suspense fallback={<Spinner />}>
      <ThemeProvider theme={muiTheme}>
        <SnackbarProvider
          maxSnack={12}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        >
          <FiltersProvider>
            <RouterProvider router={router} fallbackElement={<div></div>} />
            <SnackbarManager />
          </FiltersProvider>
        </SnackbarProvider>
      </ThemeProvider>
    </React.Suspense>
  );
}
