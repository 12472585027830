import { MuiFormControl } from '@/theme/components/formControl.js';
import { MuiIconButton } from '@/theme/components/iconButton.js';
import { MuiInput } from '@/theme/components/input.js';
import { MuiLabel } from '@/theme/components/label.js';
import { MuiPaper } from '@/theme/components/paper.js';
import { MuiTextField } from '@/theme/components/textfield.js';

import { MuiButton } from './button/index.js';
import { MuiAccordion } from './accordion.js';
import { MuiAutocomplete } from './autocomplete.js';
import { MuiCheckbox } from './checkbox.js';
import { MuiPagination } from './pagination.js';
import { MuiRadio } from './radio.js';
import { MuiSelect } from './select.js';
import { MuiTab } from './tab.js';
import { MuiTabs } from './tabs.js';

export const components = {
  MuiButton,
  MuiRadio,
  MuiCheckbox,
  MuiTabs,
  MuiTab,
  MuiIconButton,
  MuiAccordion,
  MuiAutocomplete,
  MuiSelect,
  MuiPagination,
  MuiTextField,
  MuiInput: MuiInput,
  MuiFormLabel: MuiLabel,
  MuiFormControl: MuiFormControl,
  MuiPaper,
};
