import React from 'react';
import { useNavigate } from 'react-router-dom';
import Avatar from '@mui/material/Avatar';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Tooltip from '@mui/material/Tooltip';

import ChevronDown from '@/assets/svg/ChevronDown.svg?react';
import User from '@/assets/svg/User.svg?react';
import { _useAuth } from '@/modules/Auth/stores/auth-store.js';
import { ROUTES } from '@/utils/routes.js';

import classes from './AccountMenu.module.scss';

export default function AccountMenu() {
  const { user, signOut } = _useAuth();

  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const openProfile = () => {
    navigate(ROUTES.account);
    handleClose();
  };
  const onLogout = async () => {
    await signOut();
    navigate(ROUTES.login);
  };

  return (
    <>
      <Tooltip title='Account settings'>
        <IconButton
          onClick={handleClick}
          size='small'
          sx={{ ml: 2 }}
          aria-controls={open ? 'account-menu' : undefined}
          aria-haspopup='true'
          aria-expanded={open ? 'true' : undefined}
        >
          <User />
          <div className={classes.userName}>
            <p className={classes.headerEmail}>
              {user?.first_name} {user?.last_name} <ChevronDown />
            </p>
            <p className={classes.name}>{user?.email}</p>
          </div>
        </IconButton>
      </Tooltip>
      <Menu
        anchorEl={anchorEl}
        id='account-menu'
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuItem onClick={openProfile}>
          <Avatar />
          <p className={classes.email}>{user.email}</p>
        </MenuItem>
        <Divider />
        <MenuItem onClick={onLogout}>Logout</MenuItem>
      </Menu>
    </>
  );
}
