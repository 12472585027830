import { SORT_ORDER } from '@/utils/constant.js';

export const getOrder = (direction) => {
  if (direction === SORT_ORDER.DESC) {
    return null;
  }
  if (direction === SORT_ORDER.ASC) {
    return SORT_ORDER.DESC;
  }
  return SORT_ORDER.ASC;
};
export const stringSort = (field = 'title') => {
  return (a, b) => {
    const valueA = a[field].toLowerCase();
    const valueB = b[field].toLowerCase();

    if (valueA < valueB) {
      return -1;
    }
    if (valueA > valueB) {
      return 1;
    }
    return 0;
  };
};
