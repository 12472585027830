import { rootStyles } from './rootStyles.js';
import { sizeStyles } from './sizeStyles.js';

export const MuiButton = {
  styleOverrides: {
    root: ({ theme, ...button }) => {
      return rootStyles({ theme, button });
    },
    ...sizeStyles,
  },
};
