import httpService from '@/api/http.service.js';
const ROUTE = 'material-presets';
export const getPresets = (params) => httpService.get(ROUTE, { params });
const headers = {
  'Content-Type': 'multipart/form-data',
};
export const addPreset = (body) => httpService.post(ROUTE, body, { headers });
export const editPreset = (id, body) => httpService.put(`${ROUTE}/${id}`, body);
export const getPresetById = (id) => httpService.get(`${ROUTE}/${id}`);
export const downloadPreset = (id) =>
  httpService.get(`${ROUTE}/download/${id}`);
