import httpService from '@/api/http.service.js';
const ROUTE = 'auth';

export const signInReq = ({ username, password }) =>
  httpService.post(
    `${ROUTE}/login`,
    { username, password },
    { authorization: false },
  );
export const signOutReq = (body) => httpService.post(`${ROUTE}/logout`, body);
export const changePasswordReq = (body) =>
  httpService.patch(`${ROUTE}/change-password`, body);
