import _ from 'lodash';

export const checkIfTabAccessible = (userPermissions, tabKeys) => {
  const groupedPermissions = _.groupBy(userPermissions, 'group');
  const allowedKeys = Object.keys(groupedPermissions).filter((groupKey) => {
    return groupedPermissions[groupKey]?.length >= 1;
  });

  return (
    tabKeys.filter((k) => {
      return allowedKeys.includes(k);
    })?.length > 0
  );
};
