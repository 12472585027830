import httpService from '@/api/http.service.js';
import { getQueryParameters } from '@/utils/api.js';
const ROUTE_RENDER = 'render';
const ROUTE_PROCESSING = 'processing';
const ROUTE_LAYOUTS = 'layouts';

export const renderStart = (params) =>
  httpService.get(`${ROUTE_RENDER}/start`, {
    params: getQueryParameters(params),
    timeout: undefined,
  });
export const processingStart = (params) =>
  httpService.get(`${ROUTE_PROCESSING}/start`, { params, timeout: undefined });
export const getLayouts = (params) =>
  httpService.get(ROUTE_LAYOUTS, { params });
